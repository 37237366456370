import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import SEO from "../components/seo"

const liminaire = () => (
  <Layout>
    <SEO title="Dernières oeillades - Une amie gemmologiste" />
    <h3 className='underline-title'>Une amie gemmologiste</h3>
    <br />
    <br />
    <div className="table-responsive">
      <p>
        Depuis qu'une dislocation tectonique nous a disjoints<br />
        Elle ne subit aucune équilibrante pression hydrostatique <br />
        Les choses se sont compliquées dès lors pour sa fragile statu<br />
        Celle-ci est soumise à des pressions d'inégale intensité directionnelle<br />
        Sous contrainte d'inexorable stress <br />
        D'office comme une roche à structure équante elle est devenue<br />
        Dénuée d'éléments d'orientation formelle:<br />
        Ni laminaire ni linéaire ni fibreuse<br />
        Trop rigide pour ne pas subir un écrasement cataclastique<br />
        Sa survie est d'accepter un métamorphisme<br />
        un authentique isométamorphisme <br />
        Elle doit donc recristalliser<br />
        sur une étendue régionale au moins<br />
        cristallophyllien ou chistes cristallins<br />
      </p>
      <p>
        Ah mon amie<br />
        Quelqu'un m'a dit qu'un matin<br />
        Tu t'es réveillée gemmologiste<br />
        Et que tu fais ta prospection dans de stériles basaltes<br /> 
        Egalement dans d'infructueux recouvrements de grande profonde<br />
      </p>
      <p>
        Ah mon amie<br />
        Ta chance est fort mince <br />
        pour découvrir une kimberlite minéralisée sud-africaine <br />
      </p>
      <p>
        Ah mon amie<br />
        Le même mouchard m'a dit encore <br />
        Que tes recherches artisanales ne sont ni dirigées ni méthodiques <br />
        Et que tu es sans élémentaires notions géo-minéralogiques:<br />
        En pétrographie en gitologie en gemmologie <br />
        Et que tu te fais mitrailler en zone réservée de MIBA <br />
      </p>
      <p>
        Ah mon amie<br />
        Désesperée de n'avoir rien découvert de sensationel<br />
        pas même un grain d'une commune opale <br />
        Elle est entrée dès lors dans une banale forge paysanne <br />
        Où des cliquetis avec avidité se font assourdissants <br />
        Où ma belle amie affûte sa langue vipérine <br />
        Accérée elle aspèrge des substances délétères en ma direction <br />
      </p>
      <p>
        O Rabbi<br />
        Mon amie n'est plus gemmologiste<br />
        Sa langue aiguisée débite des calomnies faussetés insinuantions...<br />
        Comment me soustraire de ce flot de poisons? <br />
        Ses <span className="font-italic">"Zuzi ya nganda"</span> (1) me vilipendent <br />
      </p>
      <p>
        O Rabbi<br />
        "Tu vois tout tu entends tou"<br />
        Apprends-lui à s'arrêter et à s'amender <br />
        Je lui pardonne tout<br />
        <span className="font-italic">"Aksanti sana"</span> (2) Rabbi<br />
      </p>
      <p>
        O mon amie<br />
        espère espère espère encore<br />
        On peut être châtié<br />
        Mais jamais abandonné<br />
      </p>
      <p>
        Il faut seulement retenir <br />
        qu'un diamant blue est fort rare.<br />
      </p>
    </div>
    <hr/>
    <p className="font-italic">
      (1) Cfr. une chanson de l'OK Jazz: "ZUZI YA NGANDA" textuellement "Juges de la buvette", 
    c'est-à-dire opportunistes, flatteurs ...qu'on rencontre fortuitement dans une buvette et qui épousent d'emblée sans avoir
    vérifié l'avis (ou l'opinion) de celui ou de celle qui achète à boire...<br/>
    (2) Merci beaucoup (grand merci) en swahili. 
    </p>
    <br />
    <br />
    <Link to="/dernieres-oeillades-table-des-matieres">Table des matières</Link>
  </Layout>
)

export default liminaire
